


















































































import { mixins } from 'vue-class-component';
import { Toasts } from '@/mixins/ToastMixins';
import { Component } from 'vue-property-decorator';
import { hasPermission } from '@/auth/AuthService';
import { PossibleAction } from '@/auth/PossibleAction';
import UserService from '@/services/UserService';
import LdapUser from '@/models/users/LdapUser';

@Component
export default class AddUser extends mixins(Toasts) {
  protected readonly PossibleAction = PossibleAction;
  protected breadcrumbs: { text: string; to?: string; active?: boolean }[] | null = null;
  protected ldapUser: LdapUser = new LdapUser('', '', '', '', '', '');
  mounted(): void {
    if (hasPermission(PossibleAction.CAN_NAVIGATE_ADMINISTRATION)) {
      this.breadcrumbs = [
        { text: 'Home', to: '/' },
        { text: 'Administration', to: '/system' },
        { text: 'Add User', active: true },
      ];
    } else {
      this.$router.replace({ path: '/' });
    }
  }

  protected setLoading(loading: boolean): void {
    this.$root.$data.loading = loading;
  }

  protected saveUser(): void {
    this.setLoading(true);
    UserService.addNewUser(this.ldapUser)
      .then(() => {
        this.setLoading(false);
        this.$router.replace({ path: '/system' });
      })
      .catch((backendError) => {
        this.setLoading(false);
        if (backendError.response.status === 403) {
          this.showToast('Action denied', 'You do not have the required rights.', 'danger');
        } else {
          this.showToast('Failed to save', 'Could not add user. ' + backendError.response.data.message, 'danger');
        }
      });
  }

  protected cancel(): void {
    if (confirm('Do you really want to cancel? Unsaved changes will be lost.')) {
      this.$router.push({ path: '/system' });
    }
  }
}
